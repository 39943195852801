<template>
    <div ref="audios" class="audios">
        <Audio @playing="onPlaying" :key="index" v-for="(audio, index) in audios" :audio="audio" />
    </div>
</template>
<script>
    import Audio from './Audio'

    export default {
        name: 'Audios',
        components: {
            Audio
        },
      props: ['audios'],
      data() {
        return {
          currentPlayer: null,
          currentAudio: null,
        }
      },
      methods: {
        onPlaying({ player, audio }) {
          if (player && audio !== this.currentAudio) {
            if (this.currentPlayer) {
              this.currentPlayer.pause()
              this.currentPlayer.currentTime = 0
            }
            this.currentPlayer = player
            this.currentAudio = audio
          }
        }
      }
    }
</script>
<style>
    .audios {
      background: #ffffff;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: space-between;
        grid-gap: 0 20px;
        /* flex-wrap: wrap;
        display: flex;
        justify-content: space-between; */
    }
    @media screen and (max-width: 1050px) {
      .audios {
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: space-around;
      }
    }
    @media screen and (max-width: 871px) {
        .audios {
          grid-template-columns: 1fr 1fr;
            justify-content: space-around;
        }
    }
    @media screen and (max-width: 600px) {
        .audios {
          grid-template-columns: 1fr;
        }
    }
</style>
