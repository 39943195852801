<template>
    <div class="works">
        <div class="work">
            <Audios :audios="audios"/>
        </div>
    </div>
</template>

<script>
    import Audios from '@/components/Audios'

    export default {
        components: {
            Audios,
        },
        data() {
          return {
            audios: [
              {
                title: ['Alicia Choi', 'G. Enescu “Impressions d\'enfance”'],
                image: 'img/01.jpg',
                description: 'Amaizing description',
                audio: 'audio/alicia_choi.wav'
              },
              {
                title: ['Yuki Isami, Natsuki Hiratsuka', 'M. Miyagi “Haru na Umi“'],
                image: 'img/02.jpg',
                description: 'Amaizing description',
                audio: 'audio/yuki.wav'
              },
              {
                title: ['Robert Silverman', 'F.Chopin “Minute Waltz Op. 64”'],
                image: 'img/12.jpg',
                imageHeight: 320,
                description: 'Amaizing description',
                audio: 'audio/silverman.wav'
              },
              {
                title: ['Maria Budacova', 'F. Liszt “Fantasie und Fugue”'],
                image: 'img/04.jpg',
                description: 'Amaizing description',
                audio: 'audio/liszt.wav'
              },
              {
                title: ['Vienna Philharmonic', 'R. Glière, P.I Tchaikowsky (Live)'],
                image: 'img/5_new.jpeg',
                imageHeight: 320,
                description: 'Amaizing description',
                audioLink: 'https://music.apple.com/us/album/gli%C3%A8re-concerto-for-harp-tchaikovsky-symphony-no-4-live/1675295847',
              },
                // remove when we sure that we don't need that
              // {
              //   title: ['Duo Bednarz - Hiratsuka', 'G. Kancheli “Sunny Night”'],
              //   image: 'img/05.jpg',
              //   description: 'Amaizing description',
              //   audio: 'audio/bednarz.wav',
              //   link: {
              //     url: 'https://metis-islands.bandcamp.com/album/giya-kancheli-sunny-night',
              //     title: 'Checkout more'
              //   }
              // },
              {
                title: ['JACK Quartet', 'Zosha Di Castri “Quartet No. 1”'],
                image: 'img/08.jpg',
                description: 'Amaizing description',
                audio: 'audio/jack.wav',
                link: {
                  url: 'https://naxosdirect.com/items/zosha-di-castri-tachitipo-514061',
                  title: 'Checkout more'
                }
              },
              {
                title: ['Geneva Trio', 'P.I. Tschaikowsky “Piano Trio in A minor”'],
                image: 'img/geneva_trio.jpg',
                imageHeight: 320,
                description: 'Amaizing description',
                audio: 'audio/geneva_trio.wav',
              },
              {
                title: ['Bach Festival Orchestra,', 'Julian Pregardien', 'J. S. Bach “Johannes-Passion”'],
                image: 'img/14.jpg',
                imageHeight: 320,
                description: 'Amaizing description',
                audio: 'audio/bach_fest.wav'
              },
              {
                title: ['National Youth Orchestra of Canada, Michael Francis', 'G. Mahler “Symphony no. 5”'],
                image: 'img/09.jpg',
                imageHeight: 320,
                description: 'Amaizing description',
                audio: 'audio/nyo.wav'
              },
              {
                title: ['Elodie Bouchard, Anthony Harvey', 'S. d\'India "Tu parti, ahi lasso"'],
                image: 'img/20.jpg',
                imageHeight: 320,
                description: 'Amaizing description',
                audio: 'audio/parti_ah.wav',
                link: {
                  url: 'https://pureaudiorecordings.com/en/product/elodie-bouchard-anthony-harvey-ha-compagnons/',
                  title: 'Checkout more'
                }
              },
              {
                title: ['Maximilian Vogler, Sebastian Issler', 'F. Schubert “Sehnsucht”'],
                image: 'img/maximilian.png',
                imageHeight: 320,
                description: 'Amaizing description',
                audio: 'audio/maximilian.wav',
              },
              {
                title: ['Abaco Orchester, V. Alekseenok, N. Boeva', 'G. Mahler “Kindertotenlieder”'],
                image: 'img/10.jpg',
                imageHeight: 320,
                description: 'Amaizing description',
                audio: 'audio/abaco.wav'
              },
              {
                title: ['Darroch/Cowan Duo', 'K. Hoover “Canyon Echoes”'],
                image: 'img/11.jpeg',
                imageHeight: 320,
                height: '102px',
                description: 'Amaizing description',
                audio: 'audio/hoover.wav'
              },
              {
                title: ['Duo Bednarz - Hiratsuka', 'G. Kancheli “Sunny Night”'],
                image: 'img/05.jpg',
                description: 'Amaizing description',
                audio: 'audio/bednarz.wav',
                link: {
                  url: 'https://metis-islands.bandcamp.com/album/giya-kancheli-sunny-night',
                  title: 'Checkout more'
                }
              },
                // remove when we sure that we don't need that
              // {
              //   title: ['Mats Eilertsen Trio', '“Rytter” (Live at Unterfahrt)'],
              //   image: 'img/mats.png',
              //   imageHeight: 320,
              //   description: 'Amaizing description',
              //   audio: 'audio/mats.wav'
              // },
              {
                title: ['Elina Duni & Rob Luft', '“Kur e Percolla Ylberin” (Live at Unterfahrt)'],
                image: 'img/elina_duni.jpg',
                imageHeight: 320,
                description: 'Amaizing description',
                audio: 'audio/elina_duni.wav',
              },
              {
                title: ['André Wickenheiser', '“A Happy Little Accident”'],
                image: 'img/15.jpg',
                imageHeight: 320,
                description: 'Amaizing description',
                audio: 'audio/andre.wav',
                link: {
                  url: 'https://www.andrewickenheiser.com/',
                  title: 'Checkout more'
                }
              },
              {
                title: ['Cole Birney-Stewart', '“The World Without Us”'],
                image: 'img/16.jpg',
                imageHeight: 320,
                description: 'Amaizing description',
                audio: 'audio/birney.wav',
                link: {
                  url: 'https://www.colebirneystewart.com',
                  title: 'Checkout more'
                }
              },
              {
                title: ['Bob Reynolds Group', '“Crush” (Live at Unterfahrt)'],
                image: 'img/crush.png',
                imageHeight: 320,
                description: 'Amaizing description',
                audio: 'audio/crush.wav',
              },
              {
                title: ['Post Scriptum Jazz “New Birthday“'],
                image: 'img/ps.jpg',
                imageHeight: 320,
                description: 'Amaizing description',
                audio: 'audio/ps.wav',
                link: {
                  url: 'https://music.apple.com/zm/artist/post-scriptum-jazz/1641817465',
                  title: 'Listen on Apple Music',
                }
              },
              {
                title: ['Charles Ives', '"The Unanswered Question"'],
                image: 'img/oliver.jpg',
                imageHeight: 320,
                description: 'Amaizing description',
                audioLink: 'https://music.apple.com/ch/album/ives-the-unanswered-question-single/1604656570?l=en'
              },
            ],
          }
        },
        mounted() {
            this.$el.style.maxHeight = 'fit-content'
        }
    }
</script>
<style>
    .works {
        display: flex;
        flex-wrap: wrap;
        background: linear-gradient(0deg, rgba(241,241,242,1) 100%, rgba(183,228,247,1) 100%);
    }
    .work {
        width: 100%;
      overflow: hidden;
    }
    .display-tablet {
        display: none;
    }
    .display-deckstop {
        display: block;
    }
    @media screen and (max-width: 1200px) {
        .work {
            width: 100%;
        }
    }
    @media screen and (max-width: 768px) {
        .display-tablet {
            display: block;
        }
        .display-deckstop {
            display: none;
        }
    }
</style>
